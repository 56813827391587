const domain_name =
  window.location.origin.indexOf("6pen") > -1 ? "mbd.6pen.art" : "mbd.pub";

console.log("domain_name", domain_name);

export default (url) => {
  return `https://${domain_name}/mbd/api/gen_qrcode?content=${encodeURIComponent(
    url
  )}`;
};
