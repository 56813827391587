<template>
  <a class="slogan" href="https://mbd.pub?from=open_c" target="_blank">
    <img src="@/assets/images/logo.png" alt="面包多" />
    <span class="slogan-text"> 面包多出品 </span>
  </a>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.slogan {
  display: block;
  position: absolute;
  left: 60px;
  top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 56px;
  padding: 0 30px;
  background: $pure-white;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.12);
  text-decoration: none;
  border-radius: 43px;
  .slogan-text {
    display: inline-block;
    padding-left: 20px;
    color: $main-text-color;
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
    text-decoration: none;
  }
  img {
    display: inline-block;
    width: 38px;
    height: 38px;
  }
}
</style>
