<template>
  <div class="home-ct">
    <header v-if="!isMobile">
      <slogan />
      <router-link to="query" class="order-query">
        <span>订单查询</span>
      </router-link>
    </header>
    <a v-if="!isMobile" class="intro" href="#"></a>
    <a v-else class="m-intro" href="#"></a>
    <div class="m-play" :class="[isMobile ? 'mobile' : 'pc']">
      <div class="m-play-block">
        <img
          @click="triggerPlay"
          src="@/assets/images/pub_play@2x.png"
          srcset="@/assets/images/pub_play@2x.png 2x"
          alt="装饰"
          class="play-btn"
        />
        <!-- <div class="mse-wrap">
          <div id="mse"></div>
        </div> -->
        <span class="text" :class="[isMobile ? 'mobile' : 'pc']">播放介绍</span>
      </div>
    </div>
    <div v-if="!isMobile" class="start">
      <router-link class="start-btn" to="/new">开始使用</router-link>
    </div>
    <div v-if="!isMobile" class="features">
      <div class="submit-area">
        <div class="features-label">
          <img
            src="@/assets/images/feature-1.png"
            srcset="@/assets/images/feature-1@2x.png 2x"
            alt="装饰"
            class="decoration desk"
          />
          <span>提交你的作品</span>
        </div>
        <div class="row">
          <span>支持文字、图片、视频、音频、软件包</span>
        </div>
        <div class="img"></div>
      </div>
      <div class="get-area">
        <div class="features-label">
          <img
            src="@/assets/images/feature-2.png"
            srcset="@/assets/images/feature-2@2x.png 2x"
            alt="装饰"
            class="decoration desk"
          />
          <span>获得公证处证书</span>
        </div>
        <div class="row">
          <span>权威机构盖章，可直接用于创作证明或法律诉讼</span>
        </div>
        <div class="img"></div>
      </div>
      <div class="more-area">
        <div class="features-label">
          <img
            src="@/assets/images/feature-3.png"
            srcset="@/assets/images/feature-3@2x.png 2x"
            alt="装饰"
            class="decoration desk"
          />
          <span>以及更多可信查询</span>
        </div>
        <div class="row">
          <span>公正云/最高法院/国家授时中心</span>
        </div>
        <div class="img"></div>
      </div>
    </div>
    <div v-else class="m-features">
      <div class="block">3步获得 「原创保护」</div>
      <div class="bottom">
        <div class="row">
          <div class="tp">
            <img
              src="@/assets/images/feature-1.png"
              srcset="@/assets/images/feature-1@2x.png 2x"
              alt="装饰"
              class="decoration desk"
            />
            <span>提交你的作品</span>
          </div>
          <div class="bt">
            <span>支持文字、图片、视频、音频、软件包</span>
          </div>
        </div>
        <div class="row">
          <div class="tp">
            <img
              src="@/assets/images/feature-2.png"
              srcset="@/assets/images/feature-2@2x.png 2x"
              alt="装饰"
              class="decoration desk"
            />
            <span>获得公证处证书</span>
          </div>
          <div class="bt">
            <span>权威机构盖章，可直接用于创作证明或法律诉讼</span>
          </div>
        </div>
        <div class="row">
          <div class="tp">
            <img
              src="@/assets/images/feature-3.png"
              srcset="@/assets/images/feature-3@2x.png 2x"
              alt="装饰"
              class="decoration desk"
            />
            <span>以及更多可信查询</span>
          </div>
          <div class="bt">
            <span>公正云/最高法院/国家授时中心</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isMobile" class="m-bt">
      <p class="m-text">请电脑访问<br />https://pro.niucodata.com</p>
      <button class="copy" @click="copyLink">复制</button>
    </div>
  </div>
</template>

<script>
import Slogan from "@/components/Slogan";

const store = require("store");

export default {
  name: "Home",
  components: {
    Slogan,
  },
  data() {
    return {
      isMobile: false,
      player: null,
      name: "mianbaoduo",
    };
  },
  async mounted() {
    let _id = store.get("temp_id");
    if (_id) {
      store.remove("temp_id");
    }
    this.isMobile = this.$device.mobile;
  },
  watch: {
    "player._hasStart"(val) {
      console.log("watch started");
      val && (this.enablePlay = true);
    },
  },
  methods: {
    triggerPlay() {
      this.$modal.show(
        {
          template: `
          <div class="mse-wrap">
            <div id="mse"></div>
          </div>
        `,
        },
        {},
        {
          width: this.isMobile ? "100%" : 800,
          height: this.isMobile ? "auto" : 600,
        },
        {
          opened: this.onOpen,
          closed: this.onClosed,
          "before-open": this.beforeOpen,
          "before-close": this.beforeClose,
        }
      );
      // setTimeout(() => {
      //   this.player.play();
      //   console.log("出发播放");
      // }, 250);
    },
    copyLink() {
      this.$copyText("https://pro.niucodata.com/#/").then(() => {
        this.$toast("地址已复制到粘贴板");
      });
    },
    beforeOpen(event) {
      console.log("Opening...");
    },
    beforeClose(event) {
      console.log("Closing...");
    },
    onClosed() {
      console.log("closed");
      this.player.destroy(true);
    },
    onOpen() {
      console.log("opened");
      console.log(document.getElementById("mse"));
      this.player = new Player({
        id: "mse",
        url: "https://cdn.2zimu.com/mbd_video_NDdfMjMzMDEzXzE2NTY4MzU3NTc1ODlfMTY1NjgzNTc1NzU4OA.mp4",
        playsinline: true,
        volume: 0.35,
        "x5-video-player-type": "h5",
        "x5-video-player-fullscreen": "true",
        width: this.isMobile ? "100%" : "800px",
        height: this.isMobile ? "500px" : "600px",
      });
    },
  },
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 789px) {
  .home-ct {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .m-intro {
    display: block;
    width: 100%;
    height: 176px;
    background-image: image-set(
      url("../assets/images/m-cover.png") 1x,
      url("../assets/images/m-cover@2x.png") 2x
    );
    background-size: contain;
    background-repeat: no-repeat;
  }
  .video-js .vjs-big-play-button {
    border: unset !important;
    background-color: unset !important;
  }
  .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    font-size: 45px;
  }
  .vjs-big-play-button {
    left: 50% !important;
    top: 50% !important;
    margin-top: -1em;
    margin-left: -1.5em;
  }
  .m-features {
    margin: 0 auto;
    width: 90vw;
    height: 56vh;
    display: flex;
    flex-direction: column;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #dddddd;
    .block {
      width: 100%;
      height: 58px;
      line-height: 58px;
      text-align: center;
      color: white;
      font-size: 20px;
      font-weight: bold;
      background: black;
      border-radius: 10px 10px 0px 0px;
    }
    .bottom {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      .row {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
        .tp {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          img {
            display: inline-block;
            vertical-align: middle;
            height: 15px;
            margin-right: 15px;
          }
          span {
            font-size: 20px;
            font-weight: bold;
            color: #191919;
            white-space: nowrap;
          }
        }
        .bt {
          color: #919191;
          font-size: 12px;
          margin-top: 10px;
        }
      }
    }
  }
  .m-bt {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .m-text {
      text-align: center;
      color: #000000;
      font-size: 15px;
      font-weight: bold;
      margin-right: 6px;
    }
    .copy {
      font-size: 14px;
      color: #2faf62;
      outline: none;
      border: none;
      background: transparent;
    }
  }
}

.home-ct {
  header {
    padding: 40px 110px 29px 60px;
    @include clearfix();
    .order-query {
      width: 184px;
      height: 50px;
      float: right;
      line-height: 50px;
      text-align: center;
      border-radius: 14px;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        transition: all 0.3s;
        @include bordered($main-text-color, 2px);
      }
      @include bordered(#ededed, 2px);
      span {
        font-size: 18px;
        color: $pure-black;
        font-weight: bold;
        text-decoration: none;
      }
    }
  }
  .m-play {
    display: flex;
    justify-content: center;
    align-items: center;
    &.pc {
      margin-top: 0.8rem;
    }
    &.mobile {
      margin-top: -6rem;
    }
    .m-play-block {
      img {
        display: block;
        width: 6rem;
      }
      .mse-wrap {
        padding-top: 2.5rem;
        padding-bottom: 2rem;
      }
      .text {
        display: block;
        font-weight: bold;
        color: #191919;
        line-height: 14px;
        &.pc {
          font-size: 18px;
        }
        &.mobile {
          font-size: 14px;
        }
      }
    }
  }
  .intro {
    display: block;
    width: 100%;
    height: 237px;
    margin-top: 29px;
    background-image: image-set(
      url("../assets/images/home.png") 1x,
      url("../assets/images/home@2x.png") 2x
    );
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
  }
  .start {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 72px;
    padding-bottom: 126px;
    .start-btn {
      width: 273px;
      height: 64px;
      font-size: 24px;
      line-height: 64px;
      text-decoration: none;
      text-align: center;
      font-weight: bold;
      color: $pure-white;
      background: $pure-black;
      cursor: pointer;
      @include border-radius(14px);
    }
  }
  .features {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      .features-label {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        img {
          display: inline-block;
          margin-right: 10px;
        }
        span {
          font-size: 24px;
          font-family: AlibabaPuHuiTi_2_85_Bold;
          color: $main-text-color;
          line-height: 33px;
          letter-spacing: 1px;
          font-weight: bold;
        }
      }
      .row {
        span {
          font-size: 18px;
          font-family: AlibabaPuHuiTi_2_85_Bold;
          color: #4a4a4a;
          line-height: 25px;
        }
      }
    }
    .submit-area {
      .img {
        display: inline-block;
        width: 324px;
        height: 89px;
        margin-top: 65px;
        background-image: image-set(
          url("../assets/images/submit.png") 1x,
          url("../assets/images/submit@2x.png") 2x
        );
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;
      }
    }
    .get-area {
      .img {
        display: inline-block;
        width: 324px;
        height: 89px;
        margin-top: 65px;
        background-image: image-set(
          url("../assets/images/get.png") 1x,
          url("../assets/images/get@2x.png") 2x
        );
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;
      }
    }
    .more-area {
      .img {
        display: inline-block;
        width: 324px;
        height: 89px;
        margin-top: 65px;
        background-image: image-set(
          url("../assets/images/search.png") 1x,
          url("../assets/images/search@2x.png") 2x
        );
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;
      }
    }
  }
}
</style>
